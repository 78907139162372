import axios from "@/libs/axios.js"

export default {
  fetchItems({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('/artisans/')
        .then(response => {
          commit('SET_ITEMS', response.data)
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },

  getItem(_, id) {
    return new Promise((resolve, reject) => {
      axios.get(`/artisans/${id}`)
        .then(response => {
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },

  addItem({ commit }, item) {
    return new Promise((resolve, reject) => {
      axios.post('/artisans/store', item)
        .then(response => {
          commit('ADD_ITEM', Object.assign(response.data.artisan, { id: response.data.artisan.id }))
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },

  updateItem({ commit }, item) {
    console.log(item);
    return new Promise((resolve, reject) => {
      axios.put(`/artisans/${item.id}`, item)
        .then(response => {
          //commit('UPDATE_ITEM', response.data.artisan)
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },

  removeItem({ commit }, itemId) {
    return new Promise((resolve, reject) => {
      axios.delete(`/artisans/${itemId}`)
        .then(response => {
          commit('REMOVE_ITEM', itemId)
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
}
