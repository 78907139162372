import axios from "@/libs/axios.js"

export default {
  fetchItems({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('/services/')
        .then(response => {
          commit('SET_ITEMS', response.data)
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },

  getItem(_, id) {
    return new Promise((resolve, reject) => {
      axios.get(`/services/${id}`)
        .then(response => {
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },

  addItem({ commit }, item) {
    return new Promise((resolve, reject) => {
      axios.post('/services/store', item)
        .then(response => {
          commit('ADD_ITEM', Object.assign(response.data.service, { id: response.data.service.id }))
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },

  updateItem({ commit }, item) {
    return new Promise((resolve, reject) => {
      axios.put(`/services/${item.id}`, item)
        .then(response => {
          commit('UPDATE_ITEM', response.data.service)
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },

  removeItem({ commit }, itemId) {
    return new Promise((resolve, reject) => {
      axios.delete(`/services/${itemId}`)
        .then(response => {
          commit('REMOVE_ITEM', itemId)
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
}
