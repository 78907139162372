import Vue from 'vue'
import VueRouter from 'vue-router'
import { canNavigate } from '@/libs/acl/routeProtection'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'dashboard',
      component: () => import('@/views/Dashboard.vue'),
      meta: {
        breadcrumb: [
          {
            text: 'dashboard',
            active: true,
          }
        ],
        title: 'HelloDepann | CRM',
        action: 'manage',
        resource: 'Dashboard',
      },
    },
    {
      path: '/services',
      name: 'services',
      component: () => import('@/views/services/Index.vue'),
      meta: {
        breadcrumb: [
          {
            text: 'Services',
            active: true,
          }
        ],
        title: 'HelloDepann | Services',
        action: 'manage',
        resource: 'Services',
      },
    },
    {
      path: '/abonnements',
      name: 'abonnements',
      component: () => import('@/views/abonnements/ListView.vue'),
      meta: {
        breadcrumb: [
          {
            text: 'Abonnements',
            active: true,
          }
        ],
        title: 'HelloDepann | Abonnements',
        action: 'manage',
        resource: 'Abonnements',
      },
    },
    {
      path: '/artisans',
      name: 'artisans',
      component: () => import('@/views/artisans/ListView.vue'),
      meta: {
        breadcrumb: [
          {
            text: 'Artisans',
            active: true,
          }
        ],
        title: 'HelloDepann | Artisans',
        action: 'manage',
        resource: 'Artisans',
      },
    },
    {
      path: '/factures',
      name: 'factures',
      component: () => import('@/views/invoices/ListView.vue'),
      meta: {
        breadcrumb: [
          {
            text: 'Factures',
            active: true,
          }
        ],
        title: 'HelloDepann | Factures',
        action: 'manage',
        resource: 'Factures',
      },
    },
    {
      path: '/mon-profil',
      name: 'mon-profil',
      component: () => import('@/views/profile/Edit.vue'),
      meta: {
        breadcrumb: [
          {
            text: 'Profil',
            active: true,
          }
        ],
        title: 'HelloDepann | Mon profil',
        action: 'manage',
        resource: 'Profil',
      },
    },
    {
      path: '/artisan/edit/:id',
      name: 'artisan-edit',
      component: () => import('@/views/artisans/edit.vue'),
      props: true,
      meta: {
        breadcrumb: [
          {
            text: 'Artisans',
            to: { name: 'artisans' }
          },
          {
            text: 'Modification',
            active: true,
          }
        ],
        title: 'HelloDepann | Artisans',
        action: 'manage',
        resource: 'Artisans',
      },
    },
    {
      path: '/artisan/view/:id',
      name: 'artisan-view',
      component: () => import('@/views/artisans/view.vue'),
      props: true,
      meta: {
        breadcrumb: [
          {
            text: 'Artisans',
            to: { name: 'artisans' }
          },
          {
            text: 'Détails',
            active: true,
          }
        ],
        rightEditBtn: 'artisan-edit',
        title: 'HelloDepann | Artisans',
        action: 'manage',
        resource: 'Artisans',
      },
    },
    {
      path: '/artisan/add/',
      name: 'artisan-add',
      component: () => import('@/views/artisans/add.vue'),
      meta: {
        breadcrumb: [
          {
            text: 'Artisans',
            to: { name: 'artisans' }
          },
          {
            text: 'Création',
            active: true,
          }
        ],
        title: 'HelloDepann | Artisans',
        action: 'manage',
        resource: 'Artisans',
      },
    },
    {
      path: '/avis',
      name: 'avis',
      component: () => import('@/views/avis/Index.vue'),
      meta: {
        breadcrumb: [
          {
            text: 'Avis',
            active: true,
          }
        ],
        title: 'HelloDepann | Avis',
        action: 'manage',
        resource: 'Avis',
      },
    },

    // Interventions

    {
      path: '/Interventions/list',
      name: 'interventions-list',
      component: () => import('@/views/interventions/List.vue'),
      meta: {
        breadcrumb: [
          {
            text: 'Interventions',
            active: true,
          },
        ],
        title: 'HelloDepann | Interventions',
        action: 'manage',
        resource: 'Interventions',
      },
    },
    {
      path: '/Interventions/add',
      name: 'interventions-add',
      component: () => import('@/views/interventions/Add.vue'),
      meta: {
        breadcrumb: [
          {
            text: 'Interventions',
            to: { name: 'interventions-list' }
          },
          {
            text: 'Création',
            active: true,
          },
        ],
        title: 'HelloDepann | Interventions',
        action: 'manage',
        resource: 'Interventions',
      },
    },
    {
      path: '/Interventions/view/:id',
      name: 'interventions-view',
      component: () => import('@/views/interventions/view/View.vue'),
      meta: {
        breadcrumb: [
          {
            text: 'Interventions',
            to: { name: 'interventions-list' }
          },
          {
            text: 'Détails',
            active: true,
          },
        ],
        rightEditBtn: 'interventions-edit',
        title: 'HelloDepann | Interventions',
        action: 'manage',
        resource: 'Interventions',
      },
    },
    {
      path: '/Interventions/edit/:id',
      name: 'interventions-edit',
      component: () => import('@/views/interventions/Edit.vue'),
      meta: {
        breadcrumb: [
          {
            text: 'Interventions',
            to: { name: 'interventions-list' }
          },
          {
            text: 'Modification',
            active: true,
          },
        ],
        title: 'HelloDepann | Interventions',
        action: 'manage',
        resource: 'Interventions',
      },
    },


    {
      path: '/not-authorized',
      name: 'not-authorized',
      component: () => import('@/views/NotAuthorized.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
        action: 'read',
      },
    },

    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
        redirectIfLoggedIn: true,
        title: 'HelloDepann | Login',
        resource: 'Auth',
        action: 'read',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
        resource: 'ACL',
        action: 'read',
        title: 'HelloDepann | 404'
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn()

  // Redirect to login if not logged in
  console.log('canNavigate :', !canNavigate(to));
  if (!canNavigate(to)) {
    console.log('to => ', to);
    if (!isLoggedIn) {
      return next({ name: 'login' })
    }
    // If logged in => not authorized
    return next({ name: 'not-authorized' })
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    const userData = getUserData()
    next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
    // return next({ name: 'dashboard' })
  }

  return next()
})

const DEFAULT_TITLE = 'HelloDepann | CRM';

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach((to, from)  => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }

  Vue.nextTick(() => {
    document.title = to.meta.title || DEFAULT_TITLE;
  });

})

export default router
