export default {
  SET_ITEMS(state, payload) {
    state.historiqueAbonnements = payload
  },

  ADD_ITEM(state, payload) {
    state.historiqueAbonnements.push(payload)
  },

}
