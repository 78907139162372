import axios from "@/libs/axios.js"

export default {
  fetchItems({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('/departements/')
        .then(response => {
          commit('SET_ITEMS', response.data)
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
}
