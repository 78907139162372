export default {
  SET_ITEMS(state, payload) {
    state.demandes = payload
  },

  ADD_ITEM(state, payload) {
    state.demandes.push(payload)
  },

  UPDATE_ITEM(state, payload) {
    const ItemIndex = state.demandes.findIndex(p => p.id == payload.id)
    Object.assign(state.demandes[ItemIndex], payload)
  },

  REMOVE_ITEM(state, itemId) {
    const ItemIndex = state.demandes.findIndex(p => p.id == itemId)
    state.demandes.splice(ItemIndex, 1)
  },

}
