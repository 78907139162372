import axios from "@/libs/axios.js"

export default {
  fetchItems({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('/demandes/')
        .then(response => {
          commit('SET_ITEMS', response.data)
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },

  getItem(_, id) {
    return new Promise((resolve, reject) => {
      axios.get(`/demandes/${id}`)
        .then(response => {
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },

  addItem({ commit }, item) {
    return new Promise((resolve, reject) => {
      axios.post('/demandes/store', item)
        .then(response => {
          commit('ADD_ITEM', Object.assign(response.data.demande, { id: response.data.demande.id }))
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },

  updateItem(_, item) {
    return new Promise((resolve, reject) => {
      axios.put(`/demandes/${item.id}`, item)
        .then(response => {
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },

  removeItem({ commit }, itemId) {
    return new Promise((resolve, reject) => {
      axios.delete(`/demandes/${itemId}`)
        .then(response => {
          commit('REMOVE_ITEM', itemId)
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },

  affecter(_ , item) {
    return new Promise((resolve, reject) => {
      axios.post(`/demandes/affecterToArtisans`, item)
        .then(response => {
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
}
