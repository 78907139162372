import axios from "@/libs/axios.js"

export default {

  fetchItems({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('/historiqueRecharges/')
        .then(response => {
          commit('SET_ITEMS', response.data)
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },

  addItem({ commit }, item) {
    return new Promise((resolve, reject) => {
      axios.post('/historiqueRecharges/store', item)
        .then(response => {
          commit('ADD_ITEM', Object.assign(response.data.historique_recharge, { id: response.data.historique_recharge.id }))
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },

}
