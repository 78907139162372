import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import abonnements from './abonnements'
import artisans from './artisans'
import demandes from './demandes'
import historiqueAbonnements from './historiqueAbonnements'
import avis from './avis'
import historiqueRecharges from './historiqueRecharges'
import services from './services'
import subServices from './subServices'
import invoices from './invoices'
import departements from './departements'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    abonnements,
    artisans,
    demandes,
    historiqueAbonnements,
    avis,
    historiqueRecharges,
    services,
    subServices,
    invoices,
    departements,
  },
  strict: process.env.DEV,
})
