export default {
  SET_ITEMS(state, payload) {
    state.subServices = payload
  },

  ADD_ITEM(state, payload) {
    state.subServices.push(payload)
  },

  UPDATE_ITEM(state, payload) {
    const ItemIndex = state.subServices.findIndex(p => p.id == payload.id)
    Object.assign(state.subServices[ItemIndex], payload)
  },

  REMOVE_ITEM(state, itemId) {
    const ItemIndex = state.subServices.findIndex(p => p.id == itemId)
    state.subServices.splice(ItemIndex, 1)
  },

}
