export default {

  SET_ITEMS(state, payload) {
    state.historiqueRecharges = payload
  },


  ADD_ITEM(state, payload) {
    state.historiqueRecharges.push(payload)
  },

}
