export default {
  SET_ITEMS(state, payload) {
    state.services = payload
  },

  ADD_ITEM(state, payload) {
    state.services.push(payload)
  },

  UPDATE_ITEM(state, payload) {
    const ItemIndex = state.services.findIndex(p => p.id == payload.id)
    Object.assign(state.services[ItemIndex], payload)
  },

  REMOVE_ITEM(state, itemId) {
    const ItemIndex = state.services.findIndex(p => p.id == itemId)
    state.services.splice(ItemIndex, 1)
  },

}
