export default {
  SET_ITEMS(state, payload) {
    state.abonnements = payload
  },

  ADD_ITEM(state, payload) {
    state.abonnements.push(payload)
  },

  UPDATE_ITEM(state, payload) {
    const ItemIndex = state.abonnements.findIndex(p => p.id == payload.id)
    Object.assign(state.abonnements[ItemIndex], payload)
  },

  REMOVE_ITEM(state, itemId) {
    const ItemIndex = state.abonnements.findIndex(p => p.id == itemId)
    state.abonnements.splice(ItemIndex, 1)
  },

}
